<template>
  <div>
    <div class="row no-gutters h-100">
      <div class="col-10 offset-1 col-lg-12 offset-lg-0">
        <div
          class="row no-gutters align-items-center h-100 justify-content-center"
        >
          <app-type-activity></app-type-activity>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppTypeActivity from "../components/AppTypeActivity.vue";
export default {
  components: { AppTypeActivity },
  name: "TipologiaAttivita",
};
</script>
