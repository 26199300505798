<template>
  <div class="col-12 col-lg-10">
    <div class="row no-gutters pt-2 pb-2">
      <div class="col-md-6">
        <p class="m-list-title">Tipologia Attività</p>
      </div>
      <div class="col-md-6">
        <div class=" d-flex justify-content-md-end align-items-center h-100">
          <b-button variant="primary" size="md" @click="newLine"
            >Nuova tipologia</b-button
          >
        </div>
      </div>
    </div>
    <b-skeleton-wrapper :loading="loading">
      <b-skeleton-table
        :rows="5"
        :columns="4"
        :table-props="{ bordered: true, striped: false }"
      >
      </b-skeleton-table>
    </b-skeleton-wrapper>
    <section v-if="loading == true">
      <b-table
        :items="listTypeActivity"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        stacked="md"
        show-empty
        small
        striped
        id="AppTypeActivity"
        thead-tr-class="m-table"
        sort-icon-left
      >
        <template #cell(Codice)="row">
          <b-badge class="m-badge"
            ><span>{{ row.item.activity.code }}</span></b-badge
          >
        </template>

        <template #cell(actions)="row">
          <b-button
            size="sm"
            @click="info(row.item, row.index, $event.target)"
            class="mr-1"
          >
            Modifica
          </b-button>
          <!--<b-button
            size="sm"
            variant="danger"
            @click="deleteRow(row.item.activity.id)"
          >
            Elimina
          </b-button>-->
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        size="sm"
        :per-page="perPage"
        aria-controls="AppTypeActivity"
        align="center"
      ></b-pagination>
    </section>
    <b-modal
      :id="infoModal.id"
      :title="infoModal.title"
      @hide="resetModal"
      ref="modal"
      centered
      @ok="handleOk"
      ok-only
      ok-title="Salva"
      header-class="text-primary"
      body-class="pb-0"
      footer-class="d-flex justify-content-start border-0 pt-0"
    >
      <form ref="form" @submit.stop.prevent="saveForm()">
        <b-form-group label="Nome" label-for="name-input" class="m-input">
          <b-form-input
            id="name-input"
            v-model="infoModal.Nome"
            maxlength="100"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Descrizione"
          label-for="desc-input"
          class="m-input"
        >
          <b-form-input id="desc-input" v-model="infoModal.desc"></b-form-input>
        </b-form-group>

        <b-form-group label="Odinamento" label-for="Ord-input" class="m-input">
          <b-form-input
            id="Ord-input"
            v-model="infoModal.Ord"
            type="number"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Codice"
          label-for="Codice-input"
          class="m-input mb-1"
        >
          <b-form-input
            id="Codice-input"
            v-model="infoModal.Codice"
            maxlength="2"
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AppTypeActivity",
  data() {
    return {
      loading: false,
      fields: [
        {
          key: "activity.sorting",
          label: "Ord.",
          sortable: true,
          thClass: "",
          tdClass: "pl-3",
        },
        {
          key: "Codice",
          sortable: false,
        },
        {
          key: "activity.name",
          label: "Nome",
          sortable: false,
          variant: "",
        },

        {
          key: "actions",
          label: "",
          sortable: false,
          thClass: "",
          tdClass: "d-flex justify-content-end ",
        },
      ],
      listTypeActivity: [],
      //Inizializazione tabella
      sortBy: "Ord",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      // Inizializzo la modal
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
    };
  },

  computed: {
    rows() {
      return this.listTypeActivity.length;
    },
  },

  created() {
    this.getListTypeActivity();
    /* window.addEventListener("beforeunload", (e) => this.getListTypeActivity(e)); */
  },

  methods: {
    ...mapActions([
      "typeActivity/getTypeActivity",
      "typeActivity/putTypeActivty",
      "typeActivity/postTypeActivty",
    ]),

    async getListTypeActivity() {
      try {
        await this.$store.dispatch("typeActivity/getTypeActivity").then(() => {
          this.listTypeActivity = this.$store.getters[
            "typeActivity/listActivity"
          ];
          this.loading = true;
          /*   console.log("listTypeActivity"); */
          return this.listTypeActivity;
        });
      } catch (e) {
        this.loading = false;
        console.log({ message: "Some error getContactsPage" });
      }
    },

    //leggo le informazione per mododicarele nella modal ed apro la modal
    info(item, index, button) {
      this.infoModal.array = index;
      this.infoModal.title = `Tipologia attività: ${item.activity.name}`;
      this.infoModal.Nome = item.activity.name;
      this.infoModal.index = item.activity.id;
      this.infoModal.Ord = item.activity.sorting;
      this.infoModal.Codice = item.activity.code;
      this.infoModal.desc = item.activity.description;
      this.infoModal.ImportCode = item.activity.importCode;
      this.infoModal.IdParent = item.idParent;
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },

    //apro la modal vuota
    newLine(item, index, button) {
      this.infoModal.title = `Nuova attività`;
      this.infoModal.Nome = ``;
      this.infoModal.index = ``;
      this.infoModal.Ord = ``;
      this.infoModal.Codice = ``;
      this.infoModal.desc = ``;
      this.infoModal.ImportCode = `1`;
      this.infoModal.IdParent = 1;
      this.$root.$emit("bv::show::modal", "info-modal", button);
    },

    //svuoto la modal
    resetModal() {
      this.infoModal.title = "";
      this.infoModal.Nome = ``;
      this.infoModal.index = ``;
      this.infoModal.Ord = ``;
      this.infoModal.Codice = ``;
      this.infoModal.desc = ``;
      this.infoModal.ImportCode = ``;
      this.infoModal.IdParent = ``;
    },

    //elimino la riga dall'array
    async deleteRow($id) {
      /* this.$store.dispatch("typeActivity/deleteTypeActivty", $id); */
      try {
        await this.$store
          .dispatch("typeActivity/deleteTypeActivty", $id)
          .then(() => {
            this.listTypeActivity = this.$store.getters[
              "typeActivity/listActivity"
            ];
            this.loading = true;

            return this.listTypeActivity;
          });
      } catch (e) {
        this.loading = false;
        console.log({ message: "Some error getContactsPage" });
      }
    },

    //Intercetto Salva della modal per fare il submit
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.saveForm();
    },

    //salvo/creo l'oggetto
    async saveForm() {
      var data = {
        index: this.infoModal.index,
        id: this.infoModal.index,
        Name: this.infoModal.Nome,
        Sorting: this.infoModal.Ord,
        Code: this.infoModal.Codice,
        Description: this.infoModal.desc,
        IdParent: this.infoModal.IdParent,
        ImportCode: this.infoModal.ImportCode,
      };

      // Salvo modifcato l'ogetto nell'array
      if (this.infoModal.index !== "") {
        /* this.$store.dispatch("typeActivity/putTypeActivty", data); */

        try {
          await this.$store
            .dispatch("typeActivity/putTypeActivty", data)
            .then(() => {
              this.listTypeActivity = this.$store.getters[
                "typeActivity/listActivity"
              ];
              this.loading = true;

              return this.listTypeActivity;
            });
        } catch (e) {
          this.loading = false;
          console.log({ message: "Some error getContactsPage" });
        }
        /* this.listTypeActivity[this.infoModal.array].activity = {
          code: this.infoModal.Codice,
          description: this.infoModal.desc,
          name: this.infoModal.Nome,
          sorting: this.infoModal.Ord,
        }; */
      } else {
        try {
          await this.$store
            .dispatch("typeActivity/postTypeActivty", data)
            .then(() => {
              this.listTypeActivity = this.$store.getters[
                "typeActivity/listActivity"
              ];
              this.loading = true;

              return this.listTypeActivity;
            });
        } catch (e) {
          this.loading = false;
          console.log({ message: "Some error getContactsPage" });
        }
      }

      this.$nextTick(() => {
        this.$bvModal.hide(this.infoModal.id);
      });
    },
  },
};
</script>
